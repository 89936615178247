import React from "react";
import Footer from "./Footer";
import Header from "./Header";

function MenteeLayout(props) {
  return (
    <>
      <section className="full-width">
        <Header />
        <div> {props.children} </div>
        <Footer />
      </section>
    </>
  );
}

export default MenteeLayout;
