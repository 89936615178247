import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { StaticText } from '../utils/StaticData';


const MentorGuard = ({ component: Component, auth, ...rest }) => {
    const userData = JSON.parse(sessionStorage.getItem("userDetails"));
    return(
        <Route {...rest} render={(props) => {
            if(sessionStorage.getItem('userType')) {
                if(sessionStorage.getItem('userType') != 'Mentee'){
                    return (<Component {...props} />)
                }
                else{
                    if(userData.completed_book_count){
                        return (<Redirect to={StaticText()._MENTEEBOOKOPTIONS} />)
                    }
                    return (<Redirect to={StaticText()._MENTEEEXP} />)
                }
            }
            else{
                return (<Redirect to='/' />)
            }
        }
        }

        />)
}

export default MentorGuard;