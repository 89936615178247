import React from "react";
import { Route, Redirect } from "react-router-dom";
import { StaticText } from "../utils/StaticData";

const MenteeGuard = ({ component: Component, auth, ...rest }) => {
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));

  return (
    <Route
      {...rest}
      render={(props) => {
        const userType = sessionStorage.getItem("userType");
        const currentPath = props.location.pathname;
        console.log("Mentee Guard called...");
        console.log(
          "user data",
          userData.survey_taken_1,
          userData.survey_taken_11
        );
        if (!userType) {
          return <Redirect to="/" />;
        }

        if (userType !== "Mentee") {
          return <Redirect to={StaticText()._MENTEELIST} />;
        }

        if (currentPath === StaticText()._MENTEESURVEY) {
          console.log("props--", props);

          if (
            (userData.week_no === 1 && !userData.survey_taken_1) ||
            (userData.week_no === 11 && !userData.survey_taken_11)
          ) {
            return <Component {...props} />;
          }

          if (userData.completed_book_count) {
            return <Redirect to={StaticText()._MENTEEBOOKOPTIONS} />;
          }
          return <Redirect to={StaticText()._MENTEEEXP} />;
        }

        if (
          currentPath === StaticText()._MENTEEEXP &&
          userData.completed_book_count
        ) {
          return <Redirect to={StaticText()._MENTEEBOOKOPTIONS} />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default MenteeGuard;
