import React from "react";
import { NavLink } from "react-router-dom";
import { StaticText } from "../../utils/StaticData";
import { SetPath } from "../Shared/Common";
import FooterCard from "./FooterCard";

function LeftSideBar() {
  return (
    <>
      <div className="nav-tab-list-left">
        <ul className="nav nav-tabs">
          <li>
            <NavLink to={StaticText()._MENTEELIST}>
              <span>
                <img
                  src={SetPath + "/assets/images/users.png"}
                  alt="My Mentees"
                />
              </span>{" "}
              My Mentees
            </NavLink>
          </li>
          {/* <li>
            <NavLink to={StaticText()._MENTORCALENDAR}>
              <span>
                <img
                  src={SetPath + "/assets/images/calendar.png"}
                  alt="Calendar"
                />
              </span>{" "}
              Calendar
            </NavLink>
          </li> */}
          <li>
            <NavLink to={StaticText()._MENTORTRAINING}>
              <span>
                <img
                  src={SetPath + "/assets/images/trainning.png"}
                  alt="Training"
                />
              </span>{" "}
              Training
            </NavLink>
          </li>
          <li>
            <NavLink to={StaticText()._MENTORLIBRARY}>
              <span>
                <img
                  src={SetPath + "/assets/images/libery.svg"}
                  alt="Library"
                />
              </span>{" "}
              Library
            </NavLink>
          </li>
          <li>
            <NavLink to={StaticText()._MENTORCONTACTS}>
              <span>
                <img
                  src={SetPath + "/assets/images/contact.svg"}
                  alt="Contact"
                />
              </span>{" "}
              Contact
            </NavLink>
          </li>
        </ul>
        <FooterCard />
      </div>
    </>
  );
}

export default LeftSideBar;
