import React from "react";
import { StaticText } from "../../utils/StaticData";

function Footer() {
  return (
    <>
      <footer className="text-center mt-5 pt-5 pb-3">
        <img
          src={StaticText()._FOLDERPATH + "/assets/images/logo_bbf.svg"}
          alt="frog"
        />
      </footer>
    </>
  );
}

export default Footer;
