import React, { lazy, Suspense, useState, useMemo, useEffect } from "react";
import { Route, Switch, NavLink, Redirect, HashRouter } from "react-router-dom";

import Loader from "./components/Loader";
import { UserContext } from "./components/UserContext";
import { StaticText } from "./utils/StaticData";
import "./App.css";
import MentorLayout from "./components/Layout/MentorLayout";
import MenteeProgressLibrary from "./Pages/MenteeProgressLibrary";
import MenteeGuard from "./guards/MenteeGuard";
import MentorGuard from "./guards/MentorGuard";
import MenteeLayout from "./components/Layout/MenteeLayout";



// import NotFound from "./Pages/404_NotFound";
// import MentorNotFound from "./Pages/MentorNotFound";
// import MenteeNotFound from "./Pages/MenteeNotFound";

const Home = lazy(() => import("./Pages/Home"));
const SelectAvatar = lazy(() => import("./Pages/SelectAvatar"));
const Survey = lazy(() => import("./Pages/Survey"));
const MenteeExplore = lazy(() => import("./Pages/MenteeExplore"));
const MenteeList = lazy(() => import("./Pages/MenteeList"));
const MenteeDetails = lazy(() => import("./Pages/MenteeDetails"));
const MentorCalendar = lazy(() => import("./Pages/MentorCalendar"));

const MentorTraining = lazy(() => import("./Pages/MentorTraining"));
const MentorLibrary = lazy(() => import("./Pages/MentorLibrary"));
const MentorContacts = lazy(() => import("./Pages/MentorContacts"));
const MenteeBookOptions = lazy(() => import("./Pages/MenteeBookOptions"));
const Logout = lazy(() => import("./Pages/Logout"));
const NotFound = lazy(() => import("./Pages/404_NotFound"));
const MentorNotFound = lazy(() => import("./Pages/MentorNotFound"));
const MenteeNotFound = lazy(() => import("./Pages/MenteeNotFound"));

function App() {
  console.log("app render");
  const initialLocaUser = sessionStorage.getItem("user") || null;
  const initialUserType = sessionStorage.getItem("userType") || null;
  console.log("initialUserType----", initialUserType);
  const [value, setValue] = useState(initialLocaUser);

  let parseVal = JSON.parse(value);
  // console.log("from app page=====", parseVal);

  useEffect(() => {
    window.scrollTo(0, 0);
    const userLocalData = sessionStorage.getItem("user");
    setValue(userLocalData);
    // unmount
    return () => {
      // console.log("cleanup==");
    };
  }, [value]);

  const providerValues = useMemo(
    () => ({ value, setValue }),
    [value, setValue]
  );
  return (
    <div>
      <HashRouter>
        <UserContext.Provider value={providerValues}>
          <Suspense fallback={<Loader />}>
            <Switch>
              {/* <Route exact path="/">
                {sessionStorage.getItem("userType") ? (
                  sessionStorage.getItem("userType") === "Mentor" ? (
                    <Redirect to={StaticText()._MENTEELIST} />
                  ) : (
                    <Redirect to={StaticText()._MENTEEEXP} />
                  )
                ) : (
                  <Home />
                )}
              </Route> */}
              <Route path="/" component={Home} exact />
              <Route
                path={StaticText()._SELECTAVTAR}
                component={SelectAvatar}
                exact
              />
              <Route path={StaticText()._LOGOUT} component={Logout} exact />

              <Route
                path={[
                  StaticText()._MENTEESURVEY,
                  StaticText()._MENTEEEXP,
                  StaticText()._MENTEEBOOKOPTIONS,
                  StaticText()._MENTEEPROGRESSLIBRARY,
                ]}
              >
                <MenteeLayout>
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      <MenteeGuard
                        path={StaticText()._MENTEESURVEY}
                        component={Survey}
                        exact
                      />
                      <MenteeGuard
                        path={StaticText()._MENTEEEXP}
                        component={MenteeExplore}
                        exact
                      />
                      <MenteeGuard
                        path={StaticText()._MENTEEBOOKOPTIONS}
                        component={MenteeBookOptions}
                        exact
                      />
                      <MenteeGuard
                        path={StaticText()._MENTEEPROGRESSLIBRARY}
                        component={MenteeProgressLibrary}
                        exact
                      />
                      <Route path="*" component={MenteeNotFound}>
                        {/* <Redirect to={StaticText()._MENTEEEXP} /> */}
                      </Route>
                    </Switch>
                  </Suspense>
                </MenteeLayout>
              </Route>
              <Route
                path={[
                  StaticText()._MENTEELIST,
                  StaticText()._MENTEEDETAILS,
                  StaticText()._MENTORCALENDAR,
                  StaticText()._MENTORTRAINING,
                  StaticText()._MENTORLIBRARY,
                  StaticText()._MENTORCONTACTS,
                ]}
              >
                <MentorLayout>
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      <MentorGuard
                        path={StaticText()._MENTEELIST}
                        component={MenteeList}
                        exact
                      />
                      <MentorGuard
                        path={StaticText()._MENTEEDETAILS + "/:id"}
                        component={MenteeDetails}
                        exact
                      />
                      <MentorGuard
                        path={StaticText()._MENTORCALENDAR}
                        component={MentorCalendar}
                        exact
                      />
                      <MentorGuard
                        path={StaticText()._MENTORTRAINING}
                        component={MentorTraining}
                        exact
                      />
                      <MentorGuard
                        path={StaticText()._MENTORLIBRARY}
                        component={MentorLibrary}
                        exact
                      />
                      <MentorGuard
                        path={StaticText()._MENTORCONTACTS}
                        component={MentorContacts}
                        exact
                      />
                      <Route path="*" component={MentorNotFound}>
                        {/* <Redirect to={StaticText()._MENTEELIST} /> */}
                      </Route>
                    </Switch>
                  </Suspense>
                </MentorLayout>
              </Route>

              <Route path="*" component={NotFound} />
              {/* <Route path="*" >
                <Redirect to="/" />
              </Route> */}
            </Switch>
          </Suspense>
        </UserContext.Provider>
      </HashRouter>
    </div>
  );
}

export default App;
