import api from '../api'

class ProgressService{

    saveReadingProgress = async (progressData) => {
        try {
            let response = await api.post(`/userProgressRoute/save_reading_progress`,progressData)
            return response
        }
        catch (err) {
            console.log(err)
        }
    }

    menteeRelDetails = async (user_rel_id) => {
        try {
            let response = await api.get(`/mentorRoute/details/${user_rel_id}`,)
            return response
        }
        catch (err) {
            console.log(err)
        }
    }

    getCurrentBook = async(sf_id,week_no)=>{
        try {
            let response = await api.get(`userProgressRoute/get_current_book/${sf_id}`,{
                params:{
                    week_no,
                }
            })
            return response
        }
        catch (err) {
            console.log(err)
        }
    }

    markReadingStatus = async (progressData) => {
        try {
            let response = await api.post(`/userProgressRoute/mark_reading_status`,progressData)
            return response
        }
        catch (err) {
            console.log(err)
        }
    }

    getPreviousCompletedBook = async(sf_id,updated_by)=>{
        try {
            let response = await api.get(`userProgressRoute/get_previous_completed_book/${sf_id}`,{
                params:{
                    updated_by,
                }
            })
            return response
        }
        catch (err) {
            console.log(err)
        }
    }


    listCompletedBooks = async(sf_id,week_no='')=>{
        try {
            let response = await api.get(`userProgressRoute/list_completed_books/${sf_id}`,{
                params:{
                    week_no,
                }
            })
            return response
        }
        catch (err) {
            console.log(err)
        }
    }

    listBooksToComplete = async(sf_id,week_no)=>{
        try {
            let response = await api.get(`userProgressRoute/list_books_to_complete/${sf_id}`,{
                params:{
                    week_no,
                }
            })
            return response
        }
        catch (err) {
            console.log(err)
        }
    }

    listLibraryBooks = async(sf_id,pageNo=0,perPage=15)=>{
        try {
            let response = await api.get(`userProgressRoute/list_library_books/${sf_id}`,{
                params:{
                    pageNo,
                    perPage
                }
            })
            return response
        }
        catch (err) {
            console.log(err)
        }
    }

    markSessionAttendance = async (attendanceData) => {
        try {
            let response = await api.post(`/userProgressRoute/mark_session_attendance`,attendanceData)
            return response
        }
        catch (err) {
            throw err
        }
    }
    getSessionAttendance = async (mentor_id,mentee_id,level_no,program_sf_id) => {
        try {
            let response = await api.get(`/userProgressRoute/get_session_attendance`,{
                params:{
                    mentor_id,
                    mentee_id,
                    level_no,
                    program_sf_id
                }
            })
            return response
        }
        catch (err) {
            console.log(err)
        }
    }

    saveSurveyProgress = async (progressData) => {
        try {
            let response = await api.post(`surveyRoute/saveSurveyProgress`,progressData)
            return response
        }
        catch (err) {
            throw err
        }
    }
    listSurveyProgress = async (mentor_id,mentee_id,level_no) => {
        try {
            let response = await api.get(`surveyRoute/listSurveyProgress`,{
                params:{
                    mentor_id,
                    mentee_id,
                    level_no
                }
            })
            return response
        }
        catch (err) {
            console.log(err)
        }
    }

    getLastPageRead = async (lib_week_id,user_id,sf_id) => {
        try {
            let response = await api.get(`/userProgressRoute/get_last_page_read`,{
                params:{
                    lib_week_id,
                    user_id,
                    sf_id
                }
            })
            return response
        }
        catch (err) {
            console.log(err)
        }
    }


}

export default new ProgressService()