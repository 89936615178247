export const StaticText = () => {
  const st = {
    // _FOLDERPATH: "", // localhost
    _FOLDERPATH: process.env.PUBLIC_URL, // 142 server 
    // _FOLDERPATH: "/bbf", // 142 server 
    _SELECTAVTAR : '/select_avatar',
    _MENTEEEXP : '/mentee_explore',
    _MENTEELIST : '/mentee_list',
    _MENTEEDETAILS : '/mentee_details',
    _MENTORCALENDAR : '/mentor_calendar',
    _MENTORTRAINING : '/mentor_training',
    _MENTORLIBRARY : '/mentor_library',
    _MENTORCONTACTS : '/mentor_contacts',
    _MENTEEBOOKOPTIONS : '/mentee_book_options',
    _MENTEEPROGRESSLIBRARY : '/mentee_progress_library',
    _MENTEESURVEY : '/mentee_survey',
    _LOGOUT : '/logout',
  };
  return st;
};
