import React from "react";
import Header from "./Header";
import LeftSideBar from "./LeftSideBar";

const MentorLayout = (props) => {
  return (
    <>
      <section className="full-width">
        <Header />
        <div className="container bg-grey px-0">
          <div className="mentor-body">
            <div className="tabs-wrapper">
              <LeftSideBar />
              {props.children}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MentorLayout;
