import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import { StaticText } from "../utils/StaticData";
import ProgressService from "../services/ProgressService";
import { API_URL, PROXY_URL } from "../api";
import "antd/dist/antd.css";
import { Modal, Button } from "antd";
import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import PDF from "@mikecousins/react-pdf";
import sample from "../samplepdf1.pdf";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  spanishBookMapping,
  spanishGuideMapping,
} from "../utils/spanishMappings";
const fpath = StaticText()._FOLDERPATH;

function MenteeProgressLibrary(props) {
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const [page, setPage] = useState(1);
  const [completedBooks, setCompletedBooks] = useState([]);
  const [pages, setPages] = useState(null);
  const [bookPDF, setBookPDF] = useState(null);
  const [visible, setVisible] = useState(false);
  const [scale, setScale] = useState(1);
  const [scrollPage, setScrollPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);

  const zoomStyle = {
    marginLeft: 10,
    cursor: "pointer",
  };

  useEffect(() => {
    console.log("fetch more effect")

    completedBookList();
  }, []);

  const onDocumentComplete = ({ numPages }) => {
    setPages(numPages);
  };
  const onDocumentError = (err) => {
    console.error("pdf viewer error:", err);
  };
  const onSetScale = (type) => {
    var newScale = type ? scale + 0.1 : scale - 0.1;
    if (newScale > 2) {
      newScale = 2;
    } else if (newScale < 0.1) {
      newScale = 0.1;
    }
    setScale(newScale);
  };
  const onPage = async (type) => {
    var newPage = type ? page + 1 : page - 1;
    if (newPage > pages) {
      newPage = 1;
    } else if (newPage < 1) {
      newPage = pages;
    }
    setPage(newPage);
    document.getElementsByClassName("ant-modal-body")[0].scrollTo(0, 0);
  };

  const exit = async () => {
    setVisible(false);
    setBookPDF(null);
  };

  const fetchMoreData = () => {
    console.log("fetch more");
    if (hasMoreData) {
      completedBookList();
    }
  };

  const completedBookList = async () => {
    try {
      let completed = await ProgressService.listLibraryBooks(
        userData.sf_id,
        scrollPage
      );
      // setCompletedBooks(completed.data.data.rows);
      if (completed.data.data["rows"].length > 0) {
        setHasMoreData(true);
      } else {
        setHasMoreData(false);
      }
      setCompletedBooks((prevState) => {
        return [...prevState, ...completed.data.data["rows"]];
      });
      setScrollPage((prevPage) => prevPage + 1);
    } catch (err) {
      console.log(err);
    }
  };

  const pdfFooter = (
    <div className="footer">
      <Button onClick={() => onPage(0)}>Previous</Button>
      <div>
        <span style={{ textAlign: "center" }}>
          Page {page} of {pages}
        </span>
        <ZoomOutOutlined style={zoomStyle} onClick={() => onSetScale(0)} />
        <ZoomInOutlined style={zoomStyle} onClick={() => onSetScale(1)} />
        <span>{Math.round(scale * 100)}%</span>
      </div>
      <Button onClick={() => onPage(1)}>Next</Button>
      <Button onClick={exit}>Exit</Button>
    </div>
  );

  return (
    <>
      {/* ---PDF Modal-------------------- */}
      <Modal
        visible={visible}
        maskClosable={false}
        onCancel={exit}
        style={{ top: 20 }}
        width={"90%"}
        footer={pdfFooter}
        bodyStyle={{ height: 600, overflowY: "auto" }}
      >
        <PDF
          file={bookPDF}
          page={page}
          onDocumentLoadFail={onDocumentError}
          onDocumentLoadSuccess={onDocumentComplete}
          scale={scale}
        />
        <p style={{ textAlign: "center" }}>
          Page {page} of {pages}
        </p>
      </Modal>
      {/* ---END PDF Modal--------------------  */}
      <div className="container px-0">
        <div className="back-section-wrapper">
          <div className="d-flex align-items-start">
            <NavLink to={StaticText()._MENTEEBOOKOPTIONS}>
              <img
                src={fpath + "/assets/images/icon_back.png"}
                alt="login-left-frame"
              />
            </NavLink>
            <div className="back-right-side">
              <p>Back to Book Explorers</p>
              <h3>Books and Parent Guides</h3>
              <p>
                {/* Parents can download books and reading guides to help therir
                children in reading these books */}
                Each book has a parent guide full of fun reading activities.
                Click the orange links to read and explore the books and
                activities together.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="body-area book-option-main-wrap">
        <div className="container ">
          {/* <InfiniteScroll
            dataLength={completedBooks.length}
            next={fetchMoreData}
            hasMore={hasMoreData}
            loader={<h4>Loading...</h4>}
          > */}
          <div className="table-responsive">
            <table className="table table-bordered book-table">
              <thead>
                <tr>
                  <th>Book Title</th>
                  <th>Level</th>
                  <th colSpan={2}>Materials</th>
                </tr>
              </thead>

              <tbody>
                {completedBooks.map((lib) => (
                  <tr key={lib.lib_week_id}>
                    <td>
                      <div className="d-flex align-items-center table-img-hld">
                        <img
                          src={
                            lib.library?.book_cover?.["0"]?.file_path
                              ? `${API_URL}/${lib.library?.book_cover?.["0"]?.file_path}`
                              : `${fpath}/assets/images/book-2.png`
                          }
                          alt="book"
                          className="mr-2"
                        />
                        {lib.library?.title}
                      </div>
                    </td>
                    <td>{lib.library?.book_level?.categoryName}</td>
                    <td>
                      <span
                        className="orenge-text"
                        onClick={() => {
                          setVisible(true);
                          setPage(1);
                          setBookPDF(
                            `${PROXY_URL}/${API_URL}/${lib.library?.book?.["0"]?.file_path}` ||
                              sample
                          );
                        }}
                      >
                        Read Book (PDF)
                      </span>
                      {spanishBookMapping[lib.library?.title] ? (
                        <>
                          <br />
                          <span
                            className="orenge-text"
                            onClick={() => {
                              setVisible(true);
                              setPage(1);
                              setBookPDF(
                                `${PROXY_URL}/${API_URL}/uploads/library/sp-books/${
                                  spanishBookMapping[lib.library?.title]
                                }`
                              );
                            }}
                          >
                            Leer el libro (PDF)
                          </span>
                        </>
                      ) : (
                        <><br/><span /></>
                      )}
                    </td>
                    <td>
                      <span
                        className="orenge-text"
                        onClick={() => {
                          setVisible(true);
                          setPage(1);
                          setBookPDF(
                            `${PROXY_URL}/${API_URL}/${lib.library?.parent_guide?.["0"]?.file_path}`
                          );
                        }}
                      >
                        View Parent Guide (PDF)
                      </span>
                      {spanishGuideMapping[lib.library?.title] ? (
                        <>
                          <br />
                          <span
                            className="orenge-text"
                            onClick={() => {
                              setVisible(true);
                              setPage(1);
                              setBookPDF(
                                `${PROXY_URL}/${API_URL}/uploads/library/sp-books/${
                                  spanishGuideMapping[lib.library?.title]
                                }`
                              );
                            }}
                          >
                            Ver guía para padres (PDF)
                          </span>
                        </>
                      ) : (
                        <><br/><span /></>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* </InfiniteScroll> */}
        </div>
      </div>
    </>
  );
}

export default MenteeProgressLibrary;
