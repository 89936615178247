export const spanishBookMapping = {
  "A Cool Experiment": "A-Cool-Experiment-2-J-FINAL-SP.pdf",
  "A Visit to the Library": "A-Visit-to-the-Library-2-G-FINAL-SP.pdf",
  "Adventures in the Canyon": "Adventures-in-the-Canyon-3-M-FINAL-SP.pdf",
  "Animals That Swim": "Animals-That-Swim-1-E-FINAL-SP.pdf",
  "Become a Junior Ranger": "Becoming-a-Junior-Ranger-3-L-FINAL-SP.pdf",
  "City Visit": "City-Visit-1-E-FINAL-SP.pdf",
  "Creatures of the Everglades": "Creatures-of-the-Everglades-3-N-FINAL-2-SP.pdf",
  "Home to the Giants": "Htme-to-the-Giants-3-L-FINAL-SP.pdf",
  "How Grand Could It Be": "How-Grand-Could-It-Be-3-M-FINAL-SP.pdf",
  "I Like Ice Cream": "I-Like-Ice-Cream-1-C-FINAL-SP.pdf",
  "I Like to Play": "I-Like-to-Play-1-C-FINAL-SP.pdf",
  "Is It Big?": "Is-It-Big-1-D-FINAL-SP.pdf",
  "Jordan Makes a Plan": "Jordan-Makes-a-Plan-2-H-FINAL-SP.pdf",
  "Journey to London": "Journey-to-London-3-P-FINAL-SP.pdf",
  "Lets Go to the Fire Station": "Lets-Go-to-the-Fire-Station-2-H-FINAL-SP.pdf",
  "Marty and the Library Book": "Marty-and-the-Library-Book-2-G-FINAL-copy-SP.pdf",
  "My British Penpal": "My-British-Penpal-3-P-FINAL-sp.pdf",
  "Safari Animals": "Safari-Animals-3-O-FINAL-2-SP.pdf",
  "Teeny Tiny": "Teeny-Tiny-1-D-FINAL-SP.pdf",
  "The Boat Ride": "The-Boat-Ride-3-N-FINAL-SP.pdf",
  "The Favorite Flavor Finders": "The-Favorite-Flavor-Finders-2-J-FINAL-SP.pdf",
  "The Safari Scavenger Hunt": "The-Safari-Scavenger-Hunt-3-O-FINAL-SP.pdf",
  "Whose Ears": "Whose-Ears-1-F-FINAL-SP.pdf",
  "Whose Eye": "Whose-Eye-1-F-FINAL-SP.pdf"
}

export const spanishGuideMapping = {
  "A Change in Plans" : "A-Change-in-Plans-PG-2-J-FINAL-SP.pdf",
  "A Cool Experiment" : "A-Cool-Experiment-PG-2-J-FINAL-SP.pdf",
  "A Trip to the Museum" : "A-Trip-to-the-Museum-PG-2-J-FINAL-SP.pdf",
  "A Visit to the Library" : "A-Visit-to-the-Library-PG-2-G-FINAL-SP.pdf",
  "Adventures in the Canyon" : "Adventures-in-the-Canyon-PG-3-M-FINAL-SP.pdf",
  "Animals of Acadia National Park" : "Animals-of-Acadia-National-Park-PG-3-K-FINAL-SP.pdf",
  "Animals That Climb" : "Animals-That-Climb-PG-1-E-FINAL-SP.pdf",
  "Animals That Crawl" : "Animals-That-Crawl-PG-1-E-FINAL-SP.pdf",
  "Animals That Fly" : "Animals-That-Fly-PG-1-E-FINAL-SP.pdf",
  "Animals That Run" : "Animals-That-Run-PG-1-E-FINAL-SP.pdf",
  "Animals That Swim" : "Animals-That-Swim-PG-1-E-FINAL-SP.pdf",
  "Bean was Mean" : "Bean-was-Mean-PG-2-I-FINAL-SP.pdf",
  "Becoming a Junior Ranger" : "Becoming-a-Junior-Ranger-PG-3-L-FINAL-SP.pdf",
  "Boom Things That Are Loud" : "Boom-Things-That-Are-Loud-PG-1-E-FINAL-SP.pdf",
  "City Visit" : "City-Visit-PG-1-E-FINAL-SP.pdf",
  "Creatures of the Everglades" : "Creatures-of-the-Everglades-PG-3-N-FINAL-SP.pdf",
  "Dance Class" : "Dance-Class-PG-1-E-FINAL-SP.pdf",
  "Fun at the Shore" : "Fun-at-the-Shore-PG-1-E-FINAL-SP.pdf",
  "Fun in the Field" : "Fun-in-the-Field-PG-1-F-FINAL-SP.pdf",
  "Home to the Giants" : "Home-to-the-Giants-PG-3-L-FINAL-SP.pdf",
  "How Grand Could It Be" : "How-Grand-Could-It-Be-PG-3-M-FINAL-SP.pdf",
  "I Like Ice Cream" : "I-Like-Ice-Cream-PG-1-C-FINAL-SP.pdf",
  "I Like Lunch" : "I-Like-Lunch-PG-1-C-FINAL-SP.pdf",
  "I Like Pets" : "I-Like-Pets-PG-1-C-FINAL-SP.pdf",
  "I Like Snacks" : "I-Like-Snacks-PG-1-C-FINAL-SP.pdf",
  "I Like to Play" : "I-Like-to-Play-PG-1-C-FINAL-SP.pdf",
  "Is It Big" : "Is-It-Big-PG-1-D-FINAL-SP.pdf",
  "Is It Full" : "Is-It-Full-PG-1-D-FINAL-SP.pdf",
  "Is It Hot" : "Is-It-Hot-PG-1-D-FINAL-SP.pdf",
  "Is It Round" : "Is-It-Round-PG-1-D-FINAL-SP.pdf",
  "Jordan Makes a Plan" : "Jordan-Makes-a-Plan-PG-2-H-FINAL-SP.pdf",
  "Journey to London" : "Journey-to-London-PG-3-P-FINAL-SP.pdf",
  "Lets Go to the Fire Station" : "Lets-Go-to-the-Fire-Station-PG-2-H-FINAL-SP.pdf",
  "Marty and the Library Book" : "Marty-and-the-Library-Book-PG-2-G-FINAL-SP.pdf",
  "Meow Who Said That" : "Meow-Who-Said-That-PG-1-E-FINAL-SP.pdf",
  "My British Penpal" : "My-British-Penpal-PG-3-P-FINAL-SP.pdf",
  "Noelle, the Curious Seagull" : "Noelle,-the-Curious-Seagull-PG-3-K-FINAL-SP.pdf",
  "Ouch Things That Sting" : "Ouch-Things-That-Sting-PG-1-E-FINAL-SP.pdf",
  "Playing at the Park" : "Playing-at-the-Park-PG-2-I-FINAL-SP.pdf",
  "Safari Animals" : "Safari-Animals-PG-3-O-FINAL-SP.pdf",
  "Something in the Woods" : "Something-in-the-Woods-PG-1-E-FINAL-SP.pdf",
  "Teeny Tiny" : "Teeny-Tiny-PG-1-D-FINAL-SP.pdf",
  "The Boat Ride" : "The-Boat-Ride-PG-3-N-FINAL-SP.pdf",
  "The Favorite Flavor Finders" : "The-Favorite-Flavor-Finders-PG-2-J-FINAL-SP.pdf",
  "The Giant Snowman" : "The-Giant-Snowman-PG-1-D-FINAL-SP.pdf",
  "The Great Race" : "The-Great-Race-PG-1-F-FINAL-SP.pdf",
  "The Safari Scavenger Hunt" : "The-Safari-Scavenger-Hunt-PG-3-O-FINAL-SP.pdf",
  "These are the People in Your Neighborhood" : "These-are-the-People-in-Your-Neighborhood-PG-2-I-FINAL-SP.pdf",
  "What it Takes" : "What-it-Takes-PG-2-I-FINAL-SP.pdf",
  "Whose Ears" : "Whose-Ears-PG-1-F-FINAL-SP.pdf",
  "Whose Egg" : "Whose-Egg-PG-1-F-FINAL-SP.pdf",
  "Whose Eye" : "Whose-Eye-PG-1-F-FINAL-SP.pdf",
  "Whose Tail" : "Whose-Tail-PG-1-F-FINAL-SP.pdf",
  "Whose Teeth" : "Whose-Teeth-PG-1-F-FINAL-SP.pdf",
  "Yuck Things That Stink" : "Yuck-Things-That-Stink-PG-1-E-FINAL-SP.pdf",
  "Zoom Things That Are Fast" : "Zoom-Things-That-Are-Fast-PG-1-E-FINAL-SP.pdf"
}