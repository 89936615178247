import React from "react";
import { SetPath } from "../Shared/Common";

function FooterCard() {
  return (
    <>
      <div className="text-center pb-4">
        <img
          src={SetPath + "/assets/images/logo_bbf.svg"}
          alt="frog"
          width="146px"
        />
      </div>
    </>
  );
}

export default FooterCard;
