import React from "react";
import { useHistory } from "react-router-dom";
import { StaticText } from "../../utils/StaticData";

function Header() {
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  console.log("userData=", userData);
  const history = useHistory();

  const logoutHandler = () => {
    sessionStorage.clear();
    history.replace(StaticText()._LOGOUT);
  };
  return (
    <>
      <div className="container">
        <header>
          <img
            src={StaticText()._FOLDERPATH + "/assets/images/be-logo.png"}
            style={{ width: "55%" }}
            alt="be-logo"
          />
          <div className="profile-section dropdown">
            <button
              className="btn  dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span>{userData ? userData.userName : ""}</span>{" "}
              <img
                src={
                  StaticText()._FOLDERPATH +
                  (userData?.avatar || "/assets/images/profile-picture.svg")
                }
                style={{ width: "58px", height: "58px" }}
                alt="login-left-frame"
              />
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <button className="dropdown-item" onClick={logoutHandler}>
                Logout
              </button>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}

export default Header;
